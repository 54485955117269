import './application.scss'

// Import the Stimulus controllers
import '@frontend/stimulus'

// Check if the rails response is requesting the preloader to be shown
import { showPreloader } from '@services/preloader'
if (window.globals.loadWithPreloader) {
  showPreloader()
}

import { createApp } from 'vue'
import type Vue from 'vue'
import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/style.css'

function registerGlobalComponents(app: Vue.App<Element>) {
  const vfm = createVfm()
  app.use(vfm)
  // .use(vfmPlugin)
  // .use(VueClickAway)
  // .component('tabs', Tabs)
  // .component('tab', Tab)
  return app
}

// Check for incoming flash messages and Toast them instead
import { toast } from '@frontend/toasts/ToastService'
if (window.flashMessages.notice) { toast.success('Success', window.flashMessages.notice) }
if (window.flashMessages.alert) { toast.error('Error', window.flashMessages.alert) }

const COMPONENT_MAP: Map<string, any> = new Map<string, any>()

// IMPORTS
import FacebookResultsTable from '@components/FacebookResultsTable.vue'
COMPONENT_MAP.set('FacebookResultsTable', FacebookResultsTable)
import LineChartPreview from '@components/LineChartPreview.vue'
COMPONENT_MAP.set('LineChartPreview', LineChartPreview)
import SendFacebookUpdateEmail from '@components/SendFacebookUpdateEmail.vue'
COMPONENT_MAP.set('SendFacebookUpdateEmail', SendFacebookUpdateEmail)
import OutboundEmails from '@components/OutboundEmails.vue'
COMPONENT_MAP.set('OutboundEmails', OutboundEmails)
import OverTime from '@components/facebook_ads/OverTime.vue'
COMPONENT_MAP.set('OverTime', OverTime)
import Scorecard from '@components/scorecard/Scorecard.vue'
COMPONENT_MAP.set('Scorecard', Scorecard)
import DashboardRoot from '@components/facebook_ads/dashboard/DashboardRoot.vue'
COMPONENT_MAP.set('DashboardRoot', DashboardRoot)
import AccountSettings from '@components/AccountSettings.vue'
COMPONENT_MAP.set('AccountSettings', AccountSettings)
import UsersPage from '@components/organizations/UsersPage.vue'
COMPONENT_MAP.set('UsersPage', UsersPage)
import Sparkline from '@components/general/Sparkline.vue'
COMPONENT_MAP.set('Sparkline', Sparkline)


const toInstantiate = document.querySelectorAll('[data-vue-component]')
if (toInstantiate.length > 0) {
  toInstantiate.forEach(el => {
    const elAttrs = el.attributes as IIndexable
    const comp = COMPONENT_MAP.get(elAttrs['data-vue-component'].value)
    const props = elAttrs['data-props']?.value
    if (comp) {
      registerGlobalComponents(
        createApp(comp, props && JSON.parse(props))
      ).mount(el)
      el.attributes.removeNamedItem('data-vue-component')
      el.attributes.removeNamedItem('data-props')
    }
  })
}


import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
flatpickr(".datepicker", {})


import tippy, { animateFill } from 'tippy.js'

// Write a small JS module that displays an SVG icon of a "code". When the icon is clicked, it should
// copy to the clipboard a preset code snippet. The value of the code snippet should be set by a html data attribute.
const clipboardCopies = document.querySelectorAll('[data-clipboard-copy]')
if (clipboardCopies.length > 0) {
  clipboardCopies.forEach(el => {
    el.classList.add('text-gray-400', 'hover:bg-gray-100', 'rounded-md', 'p-0.5', 'cursor-pointer', 'inline-block', 'ml-1')
    const elAttrs = el.attributes as IIndexable
    const type = elAttrs['data-type']?.value ?? 'default'
    if (type === 'default') {
      el.innerHTML = `<svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
      </svg>`
    } else if (type === 'code') {
      el.innerHTML = `<svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
      </svg>`
    }

    tippy(el, {
      content: `Copied: ${elAttrs['data-clipboard-copy'].value}`,
      animateFill: true,
      theme: 'hyperlcl',
      plugins: [animateFill],

      trigger: 'click',
      onShow(instance) {
        setTimeout(() => {
          instance.hide()
        }, 2000)
      }
    })
    
    el.addEventListener('click', () => {
      navigator.clipboard.writeText(elAttrs['data-clipboard-copy'].value)
    })
  })
}